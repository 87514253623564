import React, { useState, useMemo, useEffect } from 'react';
import api from 'services/api';
import DataTable from 'components/DataTable';
import {
  Card, Typography, Space, Alert, Button, Row,
} from 'antd';
import TextEditor from 'components/editors/TextEditor';
import { useAuth } from 'contexts/AuthContext';
import { useNotificationCenter } from 'contexts/NotificationCenter';
import NewDataBox from 'components/NewDataBox';
import { ProductTypesProvider } from 'contexts/ProductTypesContext';
import TableAddonsBox from 'components/TableAddonsBox';
import { ProductIntervalsProvider } from 'contexts/ProductIntervals';
import NewSellForm from 'components/forms/NewSellForm';
import { ClientsProvider } from 'contexts/ClientsContext';
import TransformationStatistics from 'components/TransformationStatistics';
import TransformationDeleteButton from 'components/editors/TransformationDeleteButton';
import { useParams } from 'react-router-dom';
import { ObjectFormatter } from 'components/formatters/ObjectFormatter';
import SellFilter from 'components/forms/SellFilter';
import WebServices from 'services/webServices';
import { useLocalization } from 'contexts/LocalizationContext';
import FormatNumberWithThousandsSeparator from '../components/formatters/formatNumberWithThousandsSeparator';

/// columns
const columns = (labelLanguage, locObject) => [
  {
    id: 'date',
    name: 'Date',
    translationKey: 'date',
    editor: TextEditor,
    sortable: true,
  },
  {
    id: 'client',
    name: 'Client',
    translationKey: 'sales_newDeclaration_client_title',
    editor: TextEditor,
    formatter: ObjectFormatter,
    requiredObjectIndex: 'companyName',
    dependencyIndex: labelLanguage,
    sortable: true,
  },
  {
    id: 'inStock',
    name: 'Produit',
    translationKey: 'product',
    editor: TextEditor,
    sortable: false,
    style: { textAlign: 'left' },
  },
  {
    id: 'inQuan',
    name: 'Quantité',
    translationKey: 'sales_newDeclaration_quantity_title',
    editor: TextEditor,
    withDecimals: false,
    sortable: false,
    formatter: (value) => FormatNumberWithThousandsSeparator(value.inQuan),
    style: { textAlign: 'right' },
    cellCustomStyle: { justifyContent: 'right' },
  },
  {
    id: 'price',
    name: 'CA (€)',
    translationKey: 'sales_table_ca',
    editor: TextEditor,
    withDecimals: false,
    sortable: false,
    formatter: (value) => FormatNumberWithThousandsSeparator(value.price),
    style: { textAlign: 'right' },
    cellCustomStyle: { justifyContent: 'right' },
  },
  {
    id: 'tgStatus',
    name: locObject.translate('tg_status'),
    editor: TextEditor,
    sortable: false,
    style: { textAlign: 'left' },
  },
  {
    id: 'delete',
    name: 'delete',
    editor: TransformationDeleteButton,
    sortable: false,
    header: () => <Space />,
  },

];
const { Title } = Typography;
const crud = {
  get: '/transformation/',
  update: '/transformation',
  delete: '/transformation',
  create: '/transformation/',
};

function Sell() {
  // Contexts

  const { sId } = useParams();
  const { user } = useAuth();
  const loc = useLocalization();
  const { locObject, currentLang } = loc;
  const notify = useNotificationCenter()();
  const dataSource = useMemo(() => new WebServices(crud, api, true, 'sell', sId ?? user.company), []);
  const [showForm, setShowForm] = useState(false);
  const [showErrorMessege, setShowErrorMessege] = useState({ visible: false, msg: '' });
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState({ total: 0, perPage: 0 });
  const [accounts, setAccounts] = useState({ values: null, isLoading: true });
  const [paritey, setParitey] = useState({ price: 0, date: '' });
  const [stocks, setStocks] = useState(null);
  const [stocksDetails, setstocksDetails] = useState(null);
  const [sales, setSales] = useState(0);
  const [sorts, setSorts] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [fieldsList, setFieldsList] = useState([]);

  const [type, setType] = useState();

  // forms controllers

  const [filterFormHidden, setFilterFormHidden] = useState({ isFormHidden: true, isButtonHidden: false });
  const [newDataFormHidden, setNewDataFormHidden] = useState({ isFormHidden: true, isButtonHidden: false });

  const sendDatasToTgBtnStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    paddingLeft: '15px',
    paddingRight: '15px',
  };

  const tgBtnStyle = {
    marginLeft: '10px',
    backgroundColor: 'black',
    color: 'white',
    opacity: isHovered ? 0.8 : 1,
  };

  // table functions

  function fetchData(page, sort, filter) {
    const response = dataSource.getTransformationTypes();
    response.then((r) => {
      const sellTransformationTypeId = r.data.find((t) => t.enumType === 'SELL');

      setType(sellTransformationTypeId);

      const respSellsAndCloses = dataSource.getSalesAndClosed(page, sort, filter);
      respSellsAndCloses.then((d) => {
        d.forEach((e) => {
          switch (e.tgStatus) {
            case 0:
              e.tgStatus = locObject.translate('tg_pending_send');
              break;
            case 1:
              e.tgStatus = locObject.translate('tg_send_validation');
              break;
            case 2:
              e.tgStatus = locObject.translate('tg_send_error');
              break;
            case 3:
              e.tgStatus = locObject.translate('tg_delete_validation');
              break;
            case 4:
              e.tgStatus = locObject.translate('tg_delete_error');
              break;
            case 5:
              e.tgStatus = locObject.translate('tg_send_progress');
              break;
            default:
              e.tgStatus = null;
              break;
          }
        });
        setData(d);
        setPagination({ total: dataSource.totalResults, perPage: dataSource.perPage });
        setFieldsList(['productType', 'client', 'amount', 'price', 'perKg']);
      });
    });
  }

  function getAccounts() {
    const response = dataSource.getAllAccounts();
    response.then((res) => {
      setAccounts({ values: res.data.filter((a) => a.account.product.enumType !== 'FLAX_STRAW' && a.account.product.enumType !== 'CARDED_FLAX'), isLoading: false });
    });
  }

  function updateStatistics() {
    const response = dataSource.getAllBalances();
    const responseDetails = dataSource.getBalancesDetails();
    response.then((resp) => {
      setStocks(resp.data);
    });
    responseDetails.then((resp) => {
      setstocksDetails(resp.data);
    });
    const resp = dataSource.getAllSells();
    resp.then((stats) => {
      setSales(stats.data.sales);
    });
  }

  useEffect(() => {
    getAccounts();
    fetchData();
    updateStatistics();
  }, []);

  function updateData(sort, filter) {
    getAccounts();
    updateStatistics();
    fetchData(1, sort, filter);
  }
  function handleDelete(rowId, company) {
    const response = dataSource.delete(rowId, company);
    response.then((res) => {
      if (res === true) {
        updateData(null, null);
        return notify({ msg: locObject.translate('suppression_success_msg'), type: 'success', desc: '' });
      }
      if (res && typeof res === 'string' && res.includes('TextileGenesis')) {
        return setShowErrorMessege({ msg: res, visible: true });
      }
      return setShowErrorMessege({ msg: locObject.translate('suppression_failed_msg'), visible: true });
    });
  }

  function sortTable(sort) {
    updateData(sort);
  }
  function handleSetFilteredData(filterValues) {
    setData(undefined);
    updateData(null, filterValues);
  }

  function handleFilterFormHidden(hidden) {
    setFilterFormHidden({ isFormHidden: hidden, isButtonHidden: !hidden });
    if (hidden === false) {
      setNewDataFormHidden({ isFormHidden: true, isButtonHidden: true });
    } else {
      setNewDataFormHidden({ isFormHidden: true, isButtonHidden: false });
    }
  }
  function handleNewDataHidden(hidden) {
    setNewDataFormHidden({ isFormHidden: hidden, isButtonHidden: !hidden });
    if (hidden === false) {
      setFilterFormHidden({ isFormHidden: true, isButtonHidden: true });
    } else {
      setFilterFormHidden({ isFormHidden: true, isButtonHidden: false });
    }
  }

  function updateAllPendingTgSalesStatus() {
    const response = dataSource.updateAllPendingTgSalesStatus();
    response.then((res) => {
      window.location.reload();
    }).catch((err) => {
      console.log(err);
    });
  }

  return (
    <Card className="d-100">
      <Space direction="vertical" size={1} className="d-100">
        <Row justify="space-between" align="center" gutter={[10, 2]}>
          <Title level={3}>{locObject.translate('sales_title')}</Title>
          <Row>
            <Button
              type="primary"
              style={sendDatasToTgBtnStyle}
              onClick={updateAllPendingTgSalesStatus}
            >
              <span style={{ lineHeight: '1' }}>{locObject.translate('tg_manual_send_data')}</span>
              <span style={{ lineHeight: '1' }}>TextileGenesis</span>
            </Button>
            <a href="https://preprod.textilegenesis.com/" target="_blank" rel="noopener noreferrer">
              <Button
                style={{ ...sendDatasToTgBtnStyle, ...tgBtnStyle }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <span style={{ lineHeight: '1' }}>{locObject.translate('tg_access')}</span>
                <span style={{ lineHeight: '1' }}>TextileGenesis</span>
              </Button>
            </a>
          </Row>
        </Row>
        <TransformationStatistics stocks={stocks} stocksDetails={stocksDetails} sales={sales} showStraw={false} showSales valueStyle={{ fontSize: '1.2vw' }} />
        <ClientsProvider>
          <ProductIntervalsProvider>
            <ProductTypesProvider>
              <NewDataBox
                onShowForm={setShowForm}
                newDataFormHidden={newDataFormHidden}
                handleNewDataHidden={handleNewDataHidden}
                showForm={showForm}
                dataSource={dataSource}
                NewDataForm={NewSellForm}
                accounts={accounts}
                type={type}
                updateData={updateData}
                company={sId ?? user.company}
                paritey={paritey}
                newDataText={locObject.translate('sales_declareButton_text')}
                title={locObject.translate('sales_newDeclaration_title')}
                activityName="sell"
                importButtonText={locObject.translate('surfaces_importPasteButton_text')}
                fieldsList={fieldsList}
              />
              <TableAddonsBox
                filterFormHidden={filterFormHidden}
                handleFilterFormHidden={handleFilterFormHidden}
                handleSetFilteredData={handleSetFilteredData}
                dataSource={dataSource}
                onShowForm={setShowForm}
                showForm={showForm}
                activityName="sell"
                showHarvestYear={false}
                showFilterButton
                showCloseMonthButton
                title={locObject.translate('filters')}
                FilterForm={SellFilter}
                exportExtras={`?type=${type?.id}`}
                exportName="Ventes"
              />
              {showErrorMessege.visible === true && (
              <Alert message={showErrorMessege.msg} type="error" closable onClose={() => setShowErrorMessege({ visible: false })} />
              )}
              <DataTable
                columns={columns(currentLang, locObject)}
                dataSource={data}
                fetchData={fetchData}
                handleDelete={handleDelete}
                sortTable={sortTable}
                paginationPerPage={pagination.perPage}
                paginationTotalResult={pagination.total}
                showBulkActions={false}
                handleCommit={() => false}
                isEditable={false}
                isPaginatble
                sorts={sorts}
                translateColumns
              />
            </ProductTypesProvider>
          </ProductIntervalsProvider>

        </ClientsProvider>
      </Space>
    </Card>
  );
}
export default Sell;
